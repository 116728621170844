nav{
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
}

.nav-list{
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #F0F0EF;
    font-family: "Helvetica Neue";
    
}

.hamburger{
    color: black;
    display: none;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    color: #767372;
}


.hamburger:hover {
    color: #F0F0EF;
}

.close {
    display: none;
}


.tabs-div a {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    float: left;
    display: block;
    color: black;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    font-family: "Helvetica Neue";
    font-size: large;
}
  
  .tabs-div a:hover {
    color: #050505;
    border-radius: 10%;
  }

  .tabs-div a:active {
    color: #8d3008;
  }


.item{
    margin-right: 20px;
    font-size: 20px;
    text-transform: uppercase;color: white;
    cursor: pointer;
}

.btn{
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: black;
    font-size: 18px;

}


#logo{
    height: auto;
    width: auto;
    max-height: 72px;
    max-width: 250px;
}


@media screen and (max-width: 35em) {
    .hamburger {
        display: block;
    }

    .close {
        display: inline;
        color: yellow;
    block-size: 2rem;
    }

      .tabs-div {
          position: fixed;
          top: 70px;
          right: 0;
          flex-direction: column;
          width: 35%;
          background: hsl(0 0%  100% / 0.1);
          border-top: 1px solid gray;
          display: none;
          text-align: center;
        
      }
      .tabs-div a{
          float: none;
          clip-path: circle();
      }

      .tabs-div.expanded {
          display: block;
          z-index: 9;
          background-color: #b3a27f;
          height: auto;
          background: hsl(0 0%  100% / 0.1);
          backdrop-filter: blur(1rem);
          padding: min(30vh, 5rem) 1em;
          inset: 0 0 0 30%;

      }

    .tabs-div.expanded a {
        text-align: center;
        margin-right: 30px;
    }



}