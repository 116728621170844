body {
    color: #F0F0EF;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #F0F0EF;
}

.category-card {
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    background: #000; 
    color: #fff; 
    transition: transform 0.2s ease-in-out;
    width: 300px; 
    height: 400px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}

.category-card:hover {
    transform: scale(1.05);
}

.category-image-container {
    height: 85%; 
    background: #000; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-image {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
}

.category-title-container {
    height: 15%; 
    background: rgba(0, 0, 0, 0.7); 
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    body {
        font-size: 1rem;
    }
}
