/**
 * Table of Content
 *
 *  > General
 *  > Info
 *  > Formulaire
 *  > Feature
 *  > Footer
 * ============================= */

/* General
   ============================= */

@font-face {
    font-family: "airiBody";
    src: url("https://fonts.gstatic.com/s/worksans/v4/QGYpz_wNahGAdqQ43Rh3j4P8mNhNy_r-Kw.woff2");
}

.back-button {
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: 1000;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #090c25;
    transition: color 0.4s ease;
}

.back-button:hover {
    color: rgb(252, 82, 31); /* Change color on hover */
}


.contact-main-container {
    margin: 0;
    font-family: "airiBody";
    font-size: 16px;
    color: rgb(70, 70, 70);
}

hr {
    width: 80px;
    height: 1px;
    border-color: rgb(252, 82, 31);
    background-color: rgb(252, 82, 31);
    margin-top: 0;
}

#info footer{
    height: 37px;
}

/* Info
 ============================= */

#info {
    height: 370px;
    padding: 50px 0;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

#info ul {
    display: flex;
    margin-top: 70px;
    margin-right: 40px;
    justify-content: space-between;
    width: 57%;
    padding-left: 0;
    margin-bottom: 30px;
}

#info li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.contact-main-container p {
    text-align: center;
    display: flex;
    line-height: 25px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    height: 35px;
    width: 35px;
    box-shadow: 0px 0px 1px rgb(175, 175, 175);
    padding: 0.5em 0.5em;
    text-align: center;
    color: rgb(252, 82, 31);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
}

.icon1:hover {
    background-color: rgb(252, 82, 31);
    color: white;
    border-color: rgb(252, 82, 31);
}

/* Formulaire
 ============================= */

#formulaire {
    height: 680px;
    padding-top: 60px;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-main-container input{
    height: 50px;
    width: 90%;
    border-radius: 20px;
}

#formulaire select{
    height: 20px;
    width: 550px;
    margin-right: 40px;
}

.contact-main-container textarea {
    height: 120px;
    width: 97%;
    border-radius:20px
}

.contact-main-container form{
    margin-top: 50px;
    margin-bottom: 30px;
    height: 70%;
    width: 70%;
}

.first-line, .second-line, .third_line{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.first-line input[type=tel] {
    width: 91%;
    border-radius: 20px;
}

.second-line input[type=text]{
    width: 94%;
    border-radius: 20px;
}

.contact-main-container button{
    background-color: rgb(252, 82, 31);
    border-radius: 25px;
    text-align: center;
    font-size: 16px;
    color: white;
    padding: 16px 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
}

.button1:hover {
    background-color: white;
    color: rgb(252, 82, 31);
    border-color: rgb(252, 82, 31);
}



/* Feature
 ============================= */

#feature {
    height: 15px;
    background-color: #00091a;
    color: steelblue;
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    padding: 50px 0;
}

.logo2{
    padding-right: 200px;
}
#feature ul {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
    width: 72%;
    padding-right: 200px;
}

#feature li {
    display: inline-block;
    padding-left: 20px;
}

#feature ul li a{
    font-size: 14px;

}

.social a{
    display: inline-block;
    margin-right: 7px; /*space between*/
    color: white;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
}

.icon2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 2px solid  rgb(31, 42, 51);
    border-radius: 20px;
    text-align: center;
}

.icon2:hover {
    background-color: rgb(252, 82, 31);
    color: white;
    border-color: rgb(252, 82, 31);
}
/* Footer
 ============================= */

.contact-main-container footer p{
    opacity: 0.7;
    text-align: center;
    font-size: 13px;
}
.contact-main-container footer{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.social{
    display: flex;
    gap: 10px;
}

