.socials-container{
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
}

.socials-container a{
    text-decoration: none;
    margin: auto;
    padding: 15px;
    color: #4D4D4D;
    display: flex; /* Added flexbox */
    align-items: center; /* Vertically center the items */
}

.socials-container a svg{
    height:30px;
    width:30px;
    vertical-align: middle; /* Ensure SVGs are vertically centered */
}

.socials-container a svg:hover{
    color: #050505;
 }

@media screen and (max-width: 650px) {
    .socials-container a span {
        display: none;
    }
}