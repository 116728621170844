
.reservation-form-container{
    justify-items: center;
    display: grid;
    align-items: center;
    justify-content: center;
    width: inherit;
}


.reservation-form-container input{
    border-radius: 15px;
    width: 400px;
    height:50px;
    margin-top: 20px;
    /*font-family: f37bergman;*/
}

.reservation-form-container button{
    border-radius: 15px;
    margin-top: 15px;
}

.reservation-form-container h3{
    color: #FC521F;
    justify-items: left;
    width: auto;
    justify-self: start;
}

.names-container{
    display: flex;
}

.firstname-container{
    display: table;
}

.error-message {
    color: red;
}

#book-button{
    background-color: #FC521F;
    border:2px solid #24673a;
    cursor: pointer;
    border-radius: 25px 25px 25px 25px;
    letter-spacing: 2px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    padding: 15px 45px 15px 45px;
    font-family: f37bergman;
    font-weight: bold;
}


@media  screen and (max-width: 425px) {
    .reservation-form-container input{
        width: 285px;
    }

}

