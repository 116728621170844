.container {
    font-family: 'Georgia', 'Times New Roman', serif;
    color: #333;
}

.about-section {
    background-color: #f9fafb;
    padding: 2rem;
    border-radius: 0.75rem;
    max-width: 800px;
    font-size: 1.1rem; /* Increased font size */
}

.about-image {
    width: 100%;
    max-width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 9999px;
    margin: 1rem auto;
    border: 4px solid #F07B0B; /* Enhanced image border */
}

.about-content h1 {
    font-size: 2.5rem; /* Larger font for the heading */
    color: #F07B0B;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 2px; /* Letter spacing for elegance */
    font-family: 'f37Bergman', serif; /* Classic serif font for headers */
}

hr {
    border-top: 3px solid #F07B0B;
    width: 35%;
    margin: 1rem auto;
}

.about-content p, .about-description p {
    font-family: 'Lora', serif; /* Elegant serif font for paragraphs */
    font-size: 1.15rem; /* Increased font size for better readability */
    color: #4a4a4a;
    line-height: 1.8rem;
    margin-bottom: 1.75rem;
    text-align: justify; /* Justified text for a neat look */
}

.about-description {
    padding: 2rem 1.5rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
}

.about-description p {
    font-family: 'Lora', serif;
    color: #4a4a4a; /* Softer text color for better readability */
}

.about-description p em {
    color: #F07B0B; /* Accent color for emphasis */
}

.socials {
    margin-top: 2rem;
}

/* Button styling */
button {
    background-color: #F07B0B; /* Set to orange */
    border: none; /* Remove default border */
    color: white; /* Set text color to white */
    border-radius: 25px; /* Make it rounded */
    padding: 16px 30px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.4s ease, color 0.4s ease;
}

button:hover {
    background-color: white; /* Change to white on hover */
    color: #F07B0B; /* Change text color to orange */
    border: 2px solid #F07B0B; /* Add border on hover */
}

/* Hover effect for text */
.about-content p:hover, .about-description p:hover {
    color: #F07B0B;
    transition: 0.3s ease;
}

.button-back {
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: 1000;
}
