/* ActivityPage.css */

.container {
  background-color: #f5f5f5; /* Light background for contrast */
  padding: 20px; /* Add some padding to the container */
  border-radius: 10px; /* Rounded corners */
}

.carousel {
  display: flex;
  overflow-x: auto; /* Horizontal scroll */
  scroll-behavior: smooth; /* Smooth scroll */
  padding: 10px; /* Padding around images */
  margin-bottom: 20px; /* Spacing below the carousel */
  border: 2px solid #F07B0B; /* Border color for carousel */
  border-radius: 10px; /* Rounded corners */
  background: white; /* White background for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.carousel img {
  width: 200px; /* Fixed width for images */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Slightly rounded corners for images */
  margin-right: 10px; /* Spacing between images */
}

.sticky-header {
  position: relative; /* Change to relative for stacking */
  padding: 20px; /* Add some padding */
  background-color: white; /* Background color for header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for header */
  border-radius: 10px; /* Rounded corners for header */
}

hr {
  margin: 10px 0; /* Spacing for horizontal line */
  border: 0; /* Remove default border */
  height: 2px; /* Height of line */
  background-color: #F07B0B; /* Line color */
}
