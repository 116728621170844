.gallery-container {
    position: relative;
    padding: 20px;
    background-color: #000; /* Black background for the gallery */
    color: #fff;
    min-height: 100vh; /* Full height of the viewport */
}

.gallery-item {
    overflow: hidden;
}

.gallery-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out; /* Smooth hover effect */
    border-radius: 8px;
}

.gallery-image:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Grid layout for responsive gallery */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default: 3 columns for mobile */
    gap: 10px;
}

@media (min-width: 600px) {
    .gallery-container {
        grid-template-columns: repeat(3, 1fr); /* Keep 3 columns for screens wider than 600px */
    }
}

@media (min-width: 900px) {
    .gallery-container {
        grid-template-columns: repeat(4, 1fr); /* 4 columns for medium screens */
    }
}

@media (min-width: 1200px) {
    .gallery-container {
        grid-template-columns: repeat(5, 1fr); /* 5 columns for larger screens */
    }
}

/* Modal styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95); /* Darker background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.modal-content {
    position: relative;
}

.modal-image {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
    border-radius: 10px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #F07B0B; /* Button color */
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    z-index: 2100;
    transition: background-color 0.3s ease-in-out;
}

.close-button:hover {
    background-color: #d0600a;
}
