/*@font-face {*/
/*    font-family: f37bergman;*/
/*    src: url("../../assets//F37Bergman-Regular.ttf")format("truetype"),*/
/*    url("../../assets/F37Bergman-Regular.woff2")format("woff2"),*/
/*    url("../../assets/F37Bergman-Regular.woff")format("woff");*/
/*}*/
.square-card-main{
    background-color: #F0F0EF;
    width: 400px;
    height: 400px;
    transition: transform 0.1s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -o-transition: transform 0.5s;
}

.square-card-main:hover{
    cursor: pointer;
}



.square-card-main:after {
    transform: scale(1);
    transition: all .5s;
}

.square-card-main:hover::after {
    transition: all .5s;
    opacity: 1;
}


.square-card-img {
    height: 85%;
    width: auto;
    background-color: #1E1E1E;
    border-radius: 15px;

}

.square-card-img img{
    height: 100%;
    width: 100%;
    border-radius: inherit;
}


.square-card-description{
    /*font-family: f37bergman, sans-serif;*/
    height: auto;
    width: auto;
    text-align: left;
    background-color: #F0F0EF;
}

.square-card-description h3{
    font-family: f37bergman, sans-serif;
    color: #100E13;
    text-align: center;
    margin-top: 10px;
    justify-items: center;
}


.clickable-container {
    position: relative;
}


/*animation to open the dialog*/
@keyframes slideUp {
    from {
        transform: translateY(100%); /* Start below the screen */
    }
    to {
        transform: translateY(0); /* End at its normal position */
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-x: unset;

    animation: slideUp 0.5s ease-in-out; /* Adjust the duration and easing as needed */

    overflow-y: auto;

}

.modal-content {
    background: #F0F0EF;
    height: 100%; /* Adjust the height as needed */
    width: 100%;
}

/* Define the slide-down animation */
@keyframes slideDown {
    from {
        transform: translateY(0); /* Start at its normal position */
    }
    to {
        transform: translateY(100%); /* End below the screen */
    }
}

/* Apply the slide-down animation to the modal */
.modal-closing {
    animation: slideDown 0.5s ease-in-out; /* Adjust the duration and easing as needed */
}

body.modal-open {
    overflow: hidden;
}

@media screen and (max-width: 1340px) {

    .square-card-img {
        height: 310px;
    }

    .square-card-img img{
        height: inherit;
    }

    .square-card-description{
        height: 30px;
    }
    .square-card-main{
        width: 300px;
        height: inherit;
    }

}

@media screen and (max-width: 425px){

    .modal-content{
        width: 100%;
    height: 100%;
    }

    .square-card-img {
        margin-inline: 30px;
      }
}

@media screen and (max-width: 633px) {
    .square-card-img{
        margin-inline: 60px;
    }
}

@media screen and (max-width: 359px){
    .square-card-img{
        margin-inline: 30px;
    }

    .square-card-main{
    }

}

.category-card {
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    /*background: white;*/
    background-color: rgba(255, 255, 255, 0.5); /* White background with 50% transparency */
    backdrop-filter: blur(10px); /* This will give a frosted glass effect on supported browsers */
    padding: 20px; /* Padding inside the card */
    margin: 10px; /* Space between cards */
    /*transition: transform 0.3s ease;*/
}

.category-card:hover {
    /*transform: scale(1.05);*/
    transform: translateY(-5px); /* Slight lift effect on hover */
    background-color: rgba(255, 255, 255, 0.7);
}

.category-image {
    height: 200px; /* Adjust as needed */
    background-size: cover;
    background-position: center;
}

.category-content {
    padding: 20px;
}

.category-title {
    /*margin: 0;*/
    font-size: 1.5rem; /* Adjust as needed */
    /*color: #333; !* Adjust as needed *!*/
    color: #000; /* Title color */
    margin-bottom: 10px; /* Space below the title */
}

.category-description {
    margin-top: 10px;
    font-size: 1rem; /* Adjust as needed */
    /*color: #666; !* Adjust as needed *!*/
    color: #333; /* Description text color */
}


@keyframes fadeInUp {
    from {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
  
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  
  .category-card-enter {
    animation: fadeInUp 0.7s ease forwards;
  }
  